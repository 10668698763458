<template>
  <v-app>
    <v-toolbar app dark>
      <v-toolbar-title class="headline text-uppercase">
        <span>Skull King &nbsp;</span>
      </v-toolbar-title>
      <v-btn text @click="openModal('GAME_RULES')">Rules</v-btn>
      <v-btn @click="openModal('PLAYER_SETTINGS')" icon="mdi-cog"></v-btn>
       <v-btn @click="openModal('GAME_STATS')" icon="mdi-chart-bar"></v-btn>
   </v-toolbar>

    <v-dialog v-model="modalOpen" max-width="500">
      <v-card>
        <v-card-title class="headline">Game Rules</v-card-title>
        <v-card-text>
          <game-rules />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal('GAME_RULES')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="playerSettingsOpen" max-width="500">
      <v-card>
        <v-card-title class="headline">Player Settings</v-card-title>
        <v-card-text>
          <player-settings />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal('PLAYER_SETTINGS')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="gameStatsOpen" max-width="500">
      <v-card>
        <v-card-title class="headline">Game Stats</v-card-title>
        <v-card-text>
          <game-stats />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal('GAME_STATS')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="gameUpdatesOpen" max-width="500">
      <v-card>
        <v-card-title class="headline">Game Updates 🚀</v-card-title>
        <v-card-text>
          <game-updates />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeModal('GAME_UPDATES')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import GameRules from './components/GameRules.vue'
import PlayerSettings from './components/PlayerSettings.vue'
import GameStats from './components/GameStats.vue'
import GameUpdates from './components/GameUpdates.vue'

export default {
  name: 'App',
  components: {
    GameRules,
    PlayerSettings,
    GameStats,
    GameUpdates
  },
  data() {
    return {
      modalOpen: false,
      playerSettingsOpen: false,
      gameStatsOpen: false,
      gameUpdatesOpen: false,
      currentVersion: 4
    }
  },
  methods: {
    closeModal(modal) {
      switch(modal) {
        case 'GAME_RULES': 
          this.modalOpen = false
          break
        case 'PLAYER_SETTINGS': 
          this.playerSettingsOpen = false
          break
        case 'GAME_STATS':
          this.gameStatsOpen = false
          break
        case 'GAME_UPDATES':
          this.gameUpdatesOpen = false
          break
      }
    },
    openModal(modal) {
      switch(modal) {
        case 'GAME_RULES': 
          this.modalOpen = true
          break
        case 'PLAYER_SETTINGS': 
          this.playerSettingsOpen = true
          break
        case 'GAME_STATS':
          this.gameStatsOpen = true
          break
      }
    },
  },
  created() {
    let lastUpdate = localStorage.getItem('currentVersion')
    // TODO: remove "!lastUpdate" to prevent showing it to new visitors
    if (!lastUpdate || lastUpdate < this.currentVersion) {
      this.gameUpdatesOpen = true
    }
  }
}
</script>
