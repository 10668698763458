<template>
  <v-sheet border rounded elevation="1" min-height="192px" class="mx-auto" max-width="370px">
    <v-row no-gutters align="center" fill-height style="margin-left:8px;margin-right:8px;">
      <v-col cols="6">
        <p class="text-body-1">Lead suit: {{ trick.leadSuit || 'Not set yet' }}</p>
      </v-col>
      <v-col cols="6" class="text-right">
        <p class="text-body-1">Trick: {{ round.trickNumber }} / {{ round.tricksToPlay }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" fill-height style="margin-left:8px;margin-right:8px;">
      <v-col cols="6">
        <p class="text-body-2">Now: {{ currentPlayer }}</p>
      </v-col>
      <v-col cols="6" class="text-right">
        <p class="text-body-2">Next: {{ nextPlayer }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" fill-height style="margin-left:4px;margin-right:4px;margin-top:16px;overflow-x: auto;">
      <div :class="playedCardOrder === 'right' ? 'scrolling-container' : 'scrolling-container-reverse'">
        <div
          v-for="(card, index) in trick.cards"
          :key="index"
          class="scrolling-item"
        >
          <card :card="card.card" :player="card.player" :index="index"/>
        </div>
      </div>
    </v-row>
  </v-sheet>
</template>


<script> 
import { state } from "@/socket";
import Card from './Card.vue';

export default {
  components: { Card },
  name: 'Trick',
  data() {
    return {}
  },
  computed: {
    trick() {
      return state.trickState
    },
    round() {
      return state.roundState
    },
    playedCardOrder() {
      return state.playerSettings.playedCardOrder
    },
    currentPlayer() {
      if (!state.welcomeGameState.players) {
        return 'N/A'
      }

      let players = state.welcomeGameState.players 
      let startingPlayer = state.trickState.startingPlayer 
      let cardsPlayed = state.trickState.cards || []
      let playerIndex = ((startingPlayer ? players.indexOf(startingPlayer) : 0) + cardsPlayed.length) % players.length

      if (playerIndex < 0 || playerIndex > players.length) {
        // in case finding index didn't work, default to 0
        return '???'
      }
      return players[playerIndex]
    },
    nextPlayer() {
      if (!state.welcomeGameState.players) {
        return 'N/A'
      }

      let players = state.welcomeGameState.players 
      let startingPlayer = state.trickState.startingPlayer 
      let cardsPlayed = state.trickState.cards || []
      // if no more cards to play
      if (cardsPlayed.length >= players.length - 1) {
        return 'N/A'
      }

      let playerIndex = ((startingPlayer ? players.indexOf(startingPlayer) : 0) + cardsPlayed.length + 1) % players.length

      if (playerIndex < 0 || playerIndex > players.length) {
        // in case finding index didn't work, default to 0
        return 'Error'
      }
      return players[playerIndex]
    }
  },
  methods: {
    formatCard(card) {
      if (card.special) {
        return card.special
      }
      if (card.character) {
        return card.character
      }
      return `${card.number} ${card.suit}`
    }
  }
}
</script>

<style scoped>
.scrolling-container {
  display: flex;
  flex-wrap: nowrap;
}

.scrolling-container-reverse {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}

.scrolling-item {
  flex: 0 0 auto;
}
</style>