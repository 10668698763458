<template>
  <div>
    <p>Total games created: {{ gamesCreated }}</p>
    <p>Total games started: {{ gamesStarted }}</p>
    <p>Total games completed: {{ gamesCompleted }}</p>
    <p>More stats will come if you play the game more :P</p>
    <p><i>Stats collected beginning Dec 26th, 2023</i></p>
  </div>
</template>

<script>

export default {
  name: 'GameStats',
  data() {
    return {
      gamesCreated: 0,
      gamesStarted: 0,
      gamesCompleted: 0
    }
  },
  methods: {
    async fetchGameStats() {
      try {
        let resp = await fetch('/api/game-stats', {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        })

        let body = await resp.json()
        console.log(body)
        this.gamesCreated = body.gamesCreated
        this.gamesStarted = body.gamesStarted
        this.gamesCompleted = body.gamesCompleted
      } catch(err) {
        console.log('Failed to get game stats', err)
        this.err = err
      }
    }
  },
  async created() {
    await this.fetchGameStats()
  }
}
</script>

<style scoped>
.wrap-text {
   white-space: normal;
   -webkit-line-clamp: unset !important;
}
</style>