<template>
  <p>A passkey allows you to create new games of Skull King.</p>
  <br>
  <p style="margin-bottom:12px;" class="text-body-2" v-if="showReason">Looks like you might not have a passkey. You can create one now or wait for someone else to create a game.</p>
  <p class="text-body-2">Passkeys are simpler to use and more secure than passwords. You can sign in using your face, fingerprint, or device passcode.</p>
  <br>
  <hr>
  <div v-if="creationStatus !== 'CREATED'">
    <div class="text-body-2" style="margin-bottom:4px;">
        <p class="d-flex justify-center">Choose a username, click create a passkey, then follow the prompts</p>
        <v-text-field type="text" v-model="username" label="Username" autocomplete="off"></v-text-field>
        <p class="d-flex justify-center">Letters or numbers (a-z,0-9): {{ getValidationEmoji(hasValidChars) }}</p>
        <p class="d-flex justify-center">Between 4-16 characters: {{ getValidationEmoji(isValidLength) }}</p>
        <p v-if="errorMsg" class="d-flex justify-center" style="color:red;">{{ errorMsg }}</p>
    </div>
    <v-row class="d-flex justify-center" style="margin-top:4px;">
      <v-btn color="primary" @click="registerKey">
        Create passkey
      </v-btn>
    </v-row>
  </div>
  <div v-else>
      <p class="text-h6">Passkey created! 🚀</p>
      <p class="text-body-2" >Let Ryan know you've created a passkey and he'll give your passkey permission to create new games</p>
  </div>
  <br>
  <hr>
    <p style="margin-top:8px;" class="text-body-2">Just to be clear: When you use your face or fingerprint with passkeys, your biometric data NEVER leaves your device and is NOT read or stored by any website or application.</p>


</template>

<script>
import axios from 'axios'

function encode(arraybuffer) {
  let s = String.fromCharCode.apply(null, new Uint8Array(arraybuffer))
  return window.btoa(s).replace(/\+/g,'-').replace(/\//g, '_')
}

function decode(str) {
  let s = window.atob(str.replace(/-/g, '+').replace(/_/g, '/'))
  let bytes = Uint8Array.from(s, c=>c.charCodeAt(0))
  return bytes.buffer
}

export default {
  name: 'CreatePasskey',
  props: {
    showReason: Boolean
  },
  data() {
    return {
      username: '',
      creationStatus: '',
      errorMsg: ''
    }
  },
  computed: {
    isValidLength() {
      return this.username.length <= 16 && this.username.length >= 4
    },
    hasValidChars() {
      const reg = /[^a-zA-Z0-9]+/ // only letters and numbers
      return !reg.test(this.username)
    },
    isRegisterDisabled() {
      return !(this.isValidLength && this.hasValidChars)
    }
  },
  methods: {
    getValidationEmoji(isValid) {
      if (!this.username) {
        return ''
      }
      return isValid ? '✅' : '⚠️' 
    },
    async registerKey() {
      if (!this.isValidLength || !this.hasValidChars) {
        // Validation criteria is already shown
        return
      }
      this.creationStatus = 'CREATING'
      try {
        const { data: options } = await axios.post('/api/generate-challenge', {purpose: 'registration', username: this.username})
      
        options.publicKey.challenge = decode(options.publicKey.challenge)
        options.publicKey.user.id = decode(options.publicKey.user.id)

        let key = await navigator.credentials.create(options)
        console.log('key', key)
        console.log('key response', key.response)
        let registerReq = {
          id: key.id,
          rawId: encode(key.rawId),
          response: {
            attestationObject: encode(key.response.attestationObject),
            clientDataJSON: encode(key.response.clientDataJSON),
            transports: key.response.getTransports()
          },
          type: key.type
        }

        await axios.post('/api/register-key', registerReq)
        this.username = ''
        this.errorMsg = ''
      } catch (error) {
        console.log(error)
        if (error.response) {
          this.errorMsg = error.response.data
        } else {
          this.errorMsg = 'Sorry, couldn\'t register a passkey'
        }
        this.creationStatus = 'ERROR'
        return      
      }

      this.creationStatus = 'CREATED'
      this.username = ''
      this.errorMsg = ''
    },
  },
}
</script>
