<template>
  <v-card 
    width="370"
  >
    <v-card-title>
      Game Log
    </v-card-title>
    <v-list-item-group class="scrollable-list">
      <v-list-item
        v-for="item in events"
        :key="item.id"
        :border="true"
      >
        {{ item.msg }}
      </v-list-item>
    </v-list-item-group>
  </v-card>
</template>

<script>
import { state } from "@/socket";

export default {
  name: 'Events',
  computed: {
    events() {
      return state.gameMessages
    }
  }
}
</script>

<style scoped>
.scrollable-list {
  height: 170px;
  overflow-y: scroll; 
  display: block;
}
</style>
