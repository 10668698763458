<template>
  <v-sheet border rounded elevation="1" height="191px" style="margin-bottom:2px;">
    <v-table 
      density="compact"
      fixed-header
      height="190px"
      border="true"
      >
      <thead>
        <tr>
          <th class="text-left">
            Turn
          </th>
          <th class="text-left">
            Player
          </th>
          <th class="text-left">
            Bet
          </th>
          <th class="text-left">
            Won 
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(bet,index) in bets"
          :key="bet.username"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ bet.username }}</td>
          <td>{{ bet.bet }}</td>
          <td>{{ bet.won }}</td>
        </tr>
      </tbody>
    </v-table>
  </v-sheet>
</template>

<script> 
import { state } from "@/socket";

export default {
  name: 'Bets',
  props: {
  },
  computed: {
    bets() {
      if (!state.welcomeGameState.players) {
        return []
      }

      let players = state.welcomeGameState.players 
      let startingPlayer = state.trickState.startingPlayer 
      let playerIndex = startingPlayer ? players.indexOf(startingPlayer) : 0

      if (playerIndex < 0 || playerIndex > players.length) {
        // in case finding index didn't work, default to 0
        playerIndex = 0
      }
      let bets = []

      for (let i = 0; i < players.length; i++) {
        let username = players[playerIndex]

        let b = {
          username: username,
          bet: '🤫',
          won: 0,
        }
        if (state.roundState.scores && username in state.roundState.scores) {
          b.bet = state.roundState.scores[username].tricksBet
          b.won = state.roundState.scores[username].tricksWon
        }
        playerIndex = (playerIndex + 1) % players.length
        bets.push(b)
      }
      return bets
    },
  },
  data() {
    return {

    }
  }
}
</script>
