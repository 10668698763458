<template>
  <div v-if="round.roundNumber > 0">
    <v-sheet border rounded elevation="0" style="padding:4px;">
      <v-row>
        <v-col cols="4">
          <p class="text-body-2 text-left">{{ name }}</p>
        </v-col>
        <v-col cols="4">
          <p class="text-body-2 text-center">{{ username }}</p>
        </v-col>
        <v-col cols="4">
          <p class="text-body-2 text-right">Round {{ round.roundNumber }} / 10</p>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
  <div v-if="round.state === 'WAITING_FOR_BETS'" class="bet">
    <v-tabs
      fixed-tabs
      v-model="placeBetTab"
      color="primary"
      align-tabs="center"
      swipeable="false"
      bg-color="#eeeeee"
    >
      <v-tab value="placeBet">Place Bet</v-tab>
      <v-tab value="players">Players</v-tab>
    </v-tabs>

    <v-window disabled v-model="placeBetTab">
      <v-window-item value="placeBet">
        <v-card
          style="margin-top:1px;margin-bottom:1px;"
          elevation="1"
          max-width="99%"
          class="mx-auto">
          <v-card-title>
            Place your bet
          </v-card-title>
          <v-card-subtitle>
            How many tricks do you think you will win (0 - {{ round.roundNumber }})?
          </v-card-subtitle>
          <v-card-text>
            <div v-if="!betPlaced">
              <v-text-field 
                @submit.prevent="placeBet" 
                type="number" 
                v-model="bet" 
                :max="round.roundNumber" 
                :min="0"
                :rules="[validBet]"/>
              <br>
              <v-row class="d-flex justify-center">
                <v-btn color="primary" @click="placeBet" type="button">Place bet</v-btn>
              </v-row>
            </div>
            <div v-else>
              <p>You bet {{ bet }}. Waiting for other bets to be placed.</p>
            </div>
          </v-card-text>
        </v-card>
      </v-window-item>

      <v-window-item value="players">
        <bets />
      </v-window-item>
    </v-window>
  </div>
  <div v-else> 
    <div v-if="round.state === 'PLAYING_TRICKS'">
      <v-tabs
        fixed-tabs
        v-model="tab"
        color="primary"
        align-tabs="center"
        swipeable="false"
        bg-color="#eeeeee"
      >
        <v-tab value="trick">Trick</v-tab>
        <v-tab value="bets">Players</v-tab>
      </v-tabs>

      <v-window disabled v-model="tab">
        <v-window-item value="trick">
          <trick />
        </v-window-item>

        <v-window-item value="bets">
          <bets />
        </v-window-item>
      </v-window>
    </div>
    <div v-if="round.state === 'END'">
      <v-row>
        <v-col cols="12">
          <round-results />
        </v-col>
      </v-row>
      <v-row  class="d-flex justify-center">
        <v-btn v-if="round.roundNumber < 10" color="primary" @click="startNextRound" type="button">Start next round</v-btn>
        <p v-else class="text-h5">🎊Game Over🎊</p>
      </v-row>

    </div>
  </div>
  <br>

  <v-sheet v-if="round.state !== 'END' && joined" border rounded elevation="1" class="mx-auto" max-width="370px">
   <v-row>
    <v-col cols="12">
      <p class="text-body-2" align="center">Your cards</p>
    </v-col>
   </v-row>
    <v-row no-gutters align="center" style="margin-left:4px; margin-right:4px; height:145px; overflow-x: auto;">
      <div class="scrolling-container">
        <div
          v-for="(card, index) of hand.cards"
          :key="index"
          class="scrolling-item"
        >
          <card :card="card" :index="index" :purpose="'HAND'" @click="selectCard(index)"/>
        </div>
      </div>
    </v-row>
    <v-row no-gutters class="d-flex justify-center">
        <v-btn v-if="askedForCard && round.state !== 'END'" color="primary" @click="playCard" type="button">Play card</v-btn>
        <p v-else-if="round.state === 'PLAYING_TRICKS'" class="text-body-1">Waiting for your turn</p>
    </v-row>
  </v-sheet>
  <br>
</template>

<script>
import { socket, state } from "@/socket";
import Card from './Card.vue';
import Bets from './Bets.vue';
import RoundResults from './RoundResults.vue'
import Trick from './Trick.vue';

export default {
  components: { Card,  RoundResults, Trick, Bets },
  name: "Round",

  data() {
    return {
      bet: 0,
      betPlaced: false,
      tab: null,
      placeBetTab: null
    }
  },
  computed: {
    hand() {
      return state.hand
    },
    gameMessage() {
      return state.gameMessage
    },
    round() {
      return state.roundState
    },
    trick() {
      return state.trickState
    },
    askedForCard() {
      return state.askedForCard
    },
    username() {
      return state.username
    },
    scores() {
      return Object.entries(state.roundState.scores).map(([k,v]) => {
        return {
          username: k,
          score: v,
        }
      })
    },
    selectedCardIndex() {
      return state.selectedCardIndex
    },
    joined() {
      return state.welcomeGameState.players.includes(state.username)
    },
    name() {
      return state.welcomeGameState.name
    }
  },
  methods: {
    isSelected(cardIndex) {
      return cardIndex === this.selectedCardIndex
    },
    placeBet() {
      // console.log('username: ', username)
      if (this.validBet() !== true) {
        return
      }
      this.betPlaced = true
      socket.emit('place-bet', {
        player: this.username,
        bet: parseInt(this.bet, 10)
      })
    },
    validBet() {
      let regex = /^\d+$/
      if (!regex.test(this.bet)) {
        return 'Bet should only contain numbers'
      }
      const intBet = parseInt(this.bet, 10)
      if (intBet < 0 || intBet > this.round.roundNumber) {
        return `Bet must be between 0 and ${this.round.roundNumber}`
      }
      return true
    },
    playCard() {
      if (this.selectedCardIndex < 0) {
        return
      }
      socket.emit('play-card', {
        player: this.username,
        card: this.hand.cards[this.selectedCardIndex]
      })
      state.removeCardFromHand(this.selectedCardIndex)
      state.updateAskedForCard(false)
      this.betPlaced = false
    },
    selectCard(index) {
      console.log('selecting card: ', index)
      state.selectCard(index)
    },
    startNextRound() {
      socket.emit('start-next-round')
    },
    formatCard(card) {
      if (card.special) {
        return card.special
      }
      if (card.character) {
        return card.character
      }
      return `${card.number} ${card.suit}`
    }
  }
}
</script>

<style scoped>
.cards {
  overflow-x: auto;
  white-space: nowrap;


  margin-bottom: 8px;
  margin-top: 8px;
}

.game-message {
  border: 1px solid black;
  max-width: 60%;
  margin: auto;
  background-color: #d6f8f4;
}

.scrolling-container {
  display: flex;
  flex-wrap: nowrap;
}

.scrolling-item {
  flex: 0 0 auto;
}
</style>
