<template>
  <v-sheet border elevation="0" max-width="380px">
    <v-table       
      density="compact"
      fixed-header
      >
      <thead>
        <tr>
          <th style="padding-right:5px;padding-left:3px">Player</th>
          <th style="padding-right:5px;padding-left:3px">Bet</th>
          <th style="padding-right:5px;padding-left:3px">Won</th>
          <th style="padding-right:5px;padding-left:3px">Hit Bet?</th>
          <th style="padding-right:5px;padding-left:3px">Bonus Points</th>
          <th style="padding-right:5px;padding-left:3px">Net Points</th>
          <th style="padding-right:5px;padding-left:3px">Total Points</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="score in scores" v-bind:key="score.username">
          <td style="padding-right:5px;padding-left:3px">{{ score.username }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.tricksBet }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.tricksWon }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.tricksBet ===  score.score.tricksWon ? '✅' : '❌' }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.bonusPointTotal }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.netPoints }}</td>
          <td style="padding-right:5px;padding-left:3px">{{ score.score.totalPoints }}</td>
        </tr>
      </tbody>
    </v-table>
  </v-sheet>
</template>

<script> 
import { state } from "@/socket";

export default {
  name: 'RoundResults',
  data() {
    return {}
  },
  computed: {
    scores() {
      return Object.entries(state.roundState.scores).map(([k,v]) => {
        return {
          username: k,
          score: v,
        }
      })
    },
  }
}
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>