<template>
  <v-btn
    color="red"
    variant="tonal"
    @click="dialog = true"
  >
    End Game
  </v-btn>

  <v-dialog
    v-model="dialog"
  >
<v-card max-width="500" class="mx-auto">
  <v-card-text>
    Are you sure you want to end the game? All progress will be lost. This cannot be undone.
  </v-card-text>

  <v-card-actions>
    <v-row justify="center">
      <v-btn color="primary" variant="elevated" @click="dialog = false">Cancel</v-btn>
      <v-btn color="red" variant="tonal" @click="endGame">End Game</v-btn>
    </v-row>
  </v-card-actions>

</v-card>


  </v-dialog>

</template>

<script> 
export default {
  name: 'EndGame',

  data() {
    return {
      dialog: false
    }
  },
  methods: {
    async endGame() {
      this.dialog = false
      fetch(`/api/end-game/${this.$route.params.gameId}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'}
      })
    }
  }
}
</script>
