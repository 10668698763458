<template>
  <div v-if="cardSize === 'normal'" class="card-player">
    <div class="card" :style="cardStyle">
      <div v-if="card.suit" :class="iconStyle()">
        <u>{{ formatIconText() }}</u>
      </div>
      <v-icon v-else
        :color="getIconColor()"
        size='large'
        :icon="getIcon()"
        style="height:30px;width:30px;"
      >
      </v-icon>
      <div class="d-flex justify-center align-center" style="height:60px">
          <p class="text-body-1">{{ formatCardText() }}</p>
      </div>
      <div class="right">
        <div v-if="card.suit" :class="iconStyle(['bottom'])">
          <u>{{ formatIconText() }}</u>
        </div>
        <v-icon v-else
          :color="getIconColor()"
          size='large'
          :icon="getIcon()"
          class="bottom"
          style="height:30px;width:30px;"
        >
        </v-icon>
      </div>
    </div>
    <v-row v-if="player" no-gutters align="center" fill-height>
      <v-col cols="6">
        <p class="text-body-2 text-left">{{ player }}</p>
      </v-col>
      <v-col cols="6">
        <p class="text-body-2 text-right">{{ index + 1 }}</p>
      </v-col>
    </v-row>
  </div>
  <div v-else class="card-player-small">
    <div class="card" :style="cardStyle">
      <div v-if="card.suit" :class="iconStyle()" style="margin:auto;">
        <u>{{ formatIconText() }}</u>
      </div>
      <div v-else class="d-flex justify-center align-center">
        <v-icon 
          :color="getIconColor()"
          size='large'
          :icon="getIcon()"
          class="center-icon"
          style="height:30px;width:30px;"
        >
        </v-icon>
      </div>
      <div class="d-flex justify-center align-center" style="height:60px">
          <p class="text-body-1">{{ formatSmallCardText() }}</p>
      </div>
    </div>
    <v-row v-if="player" no-gutters align="center" fill-height>
      <v-col cols="6">
        <p class="text-body-2 text-right">{{ index + 1 }}</p>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { state } from "@/socket";


export default {
  name: 'Card',
  props: {
    card: Object,
    index: Number,
    purpose: String,
    player: String,
  },
  computed: {
    isSelected() {
      return this.purpose === 'HAND' && state.selectedCardIndex === this.index
    },
    cardStyle() {
      const style = {
        border: "1px solid black",
        color: "white",
      }

      if (this.card.suit) {
        style.backgroundColor = this.card.suit
        if (this.card.suit === 'YELLOW') {
          style.color = "black"
          style.border = "1px solid black"
        }
      }

      if (this.card.character) {
        style.backgroundColor = "#c60c0c"
      }

      if (this.card.special) {
        style.backgroundColor = "#a45136"
      }

      if (this.purpose === 'HAND' && !this.card.isEligible) {
        style.backgroundColor = 'gray'
      }

      if (this.isSelected) {
        style.border = "3px solid #2793ff"
      }

      return style
    },
    cardSize() {
      return state.playerSettings.cardSize || 'normal'
    }
  },
  methods: {
    iconStyle(params) {
      let classes = ['icon'].concat(params)
      if (this.card.suit === 'YELLOW') {
        classes.push('black')
        return classes
      }
      return classes
    },
    getIcon() {
      if (this.card.character) {
        switch (this.card.character) {
          case 'PIRATE':
            return 'mdi-pirate'
          case 'SKULL_KING':
            return 'mdi-skull-outline'
          case 'MERMAID':
            return 'mdi-face-woman-shimmer-outline'
        }
      }
      if (this.card.special) {
        switch (this.card.special) {
          case 'ESCAPE':
            return 'mdi-flag-variant-outline'
          case 'KRAKEN':
            return 'mdi-nuke'
          case 'WHITE_WHALE':
            return 'mdi-fishbowl'
        }
      }
    },
    getIconColor() {
      if (this.card.suit === 'YELLOW') {
        return 'black'
      }
      return 'white'
    },
    formatIconText() {
      if (this.card.number) {
        return this.card.number 
      }
      if (this.card.special) {
        let parts = this.card.special.split('_')
        return parts.map(p => p[0]).join('')
      }
      if (this.card.character) {
        let parts = this.card.character.split('_')
        return parts.map(p => p[0]).join('')
      }
    },
    formatCardText() {
      if (this.card.special) {
        return this.card.special.replace('_', ' ') 
      }
      if (this.card.character) {
        return this.card.character.replace('_', ' ') 
      }
      return `${this.card.number} ${this.card.suit}`
    },
    formatSmallCardText() {
      if (this.card.special) {
        return this.card.special[0]
      }
      if (this.card.character) {
        return this.card.character[0]
      }
      return this.card.suit[0]
    }
  }
}
</script>

<style scoped>
.card-player {
  display: inline-block;
  width: 90px;
  margin: auto;
  margin-left: 4px;
  margin-right: 4px;
}

.card-player-small {
  display: inline-block;
  width: 40px;
  height: 90px;
  margin: auto;
  margin-left: 4px;
  margin-right: 4px;
}

.icon {
  border: 1px solid white;
  width: 30px; 
  height: 30px;
  border-radius: 50%;
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.bottom {
  transform: rotate(180deg);
}

.black {
  color: black;
  border: 1px solid black;
}

.center-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}
</style>
