<template>
  <div v-if="isLoading">
    <p>Loading...</p>
  </div>
  <v-container v-else class="d-flex align-center justify-center">
     <div v-if="status === 'WAITING_FOR_PLAYERS' || status === 'CREATED' || status === 'GAME_FULL'">
        <v-card>
          <v-card-title>
            {{ name }}
          </v-card-title>
          <v-card-subtitle>
            2 min / 10 max
          </v-card-subtitle>

          <v-card-text>
            <form v-if="!joined" @submit.prevent="joinGame">
              <div v-if="!showReconnect || !disconnectedPlayers.length"> 
                <div class="text--primary">
                  Enter a username to join the game
                </div>
                <v-text-field 
                  v-model="value" 
                  placeholder="username" 
                  autocomplete="null"
                  :rules="[validUsername]"
                  />
              </div>
              <div v-else>
                <div class="text--primary">
                  Choose your username
                </div>
                <v-card-text>
                  <v-chip 
                    v-for="player in disconnectedPlayers" 
                    v-bind:key="player"
                    @click="chipClicked(player)"
                    :class="{ 'outlined-chip': isSelected(player) }"
                  >{{ player }}</v-chip>
                </v-card-text>
              </div>

              <div class="d-flex justify-center">
                <v-btn @click="joinGame" color="primary">{{ !showReconnect ? 'Join game' : 'Re-join game' }}</v-btn>
              </div>
              <br>
              <div  v-if="disconnectedPlayers.length" class="d-flex justify-center">
                <v-btn @click="toggleReconnect" color="secondary">{{ !showReconnect ? 'Already Joined?' : 'New player?' }}</v-btn>
              </div>
            </form>
            <div v-else>
              <div class="text--primary">
                Ahoy there {{ username }}!
              </div>
              <div class="text--primary">
                 Start the game once everyone is on board
              </div>
              <div class="d-flex justify-center">
                <v-btn color='primary' @click="startGame">Start Game</v-btn>
              </div>
            </div>
          </v-card-text>          
        </v-card>
        <br>
        <v-card>
          <v-card-subtitle>
            {{ players.length }} / 10 players 
          </v-card-subtitle>

          <v-card-text>
            <v-chip v-for="player in players" v-bind:key="player">{{ player }}</v-chip>
          </v-card-text>
        </v-card>
    </div>
    <div v-if="status === 'GAME_IN_PROGRESS'"> 
      <div v-if="!joined">
        <p class="text-body-1 justify-center d-flex">Spectating a game in progress.</p>
        <v-card v-if="disconnectedPlayers.length">
          <v-card-title>
            Disconnected? Welcome back 😄 
          </v-card-title>

          <v-card-text>
            <form  @submit.prevent="getGameStatus">
              <div>
                <div class="text--primary">
                  Choose a username
                </div>
                <v-card-text>
                  <v-chip 
                    v-for="player in disconnectedPlayers" 
                    v-bind:key="player"
                    @click="chipClicked(player)"
                    :class="{ 'outlined-chip': isSelected(player) }"
                  >{{ player }}</v-chip>
                </v-card-text>
              </div>

              <div class="d-flex justify-center">
                <v-btn @click="getGameStatus" color="primary">Rejoin Game</v-btn>
              </div>
            </form>
          </v-card-text>     
        </v-card>
        <br>
      </div>
      <round v-if="joined" />
      <round-spectator v-else />
      <events />
    </div>

  </v-container>
  <div v-if="status && status !== 'NOT_STARTED'" class="text-center">
    <end-game />
  </div>

  <v-snackbar
      v-model="snackbar"
      :timeout="1500"
    >
    {{ snackbarMessage }}

    <template v-slot:actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { socket, state } from "@/socket";
import Round from './Round.vue';
import RoundSpectator from './RoundSpectator.vue'
import Events from './Events.vue';
import EndGame from './EndGame.vue';

export default {
  components: { Round, RoundSpectator, EndGame, Events },
  name: "Game",

  data() {
    return {
      isLoading: false,
      password: '',
      value: sessionStorage.getItem('username') || '',
      errorMessage: '',
      snackbar: false,
      snackbarMessage: '',
      showReconnect: false,
      selectedPlayer: ''
    }
  },

  computed: {
    status() {
      return state.welcomeGameState.status
    },
    players() {
      return state.welcomeGameState.players 
    },
    disconnectedPlayers() {
      return state.welcomeGameState.disconnectedPlayers
    },
    round() {
      if (state.roundState && state.roundState.roundNumber) {
        return state.roundState.roundNumber
      }
      return state.welcomeGameState.round + 1
    },
    joined() {
      return state.welcomeGameState.players.includes(state.username)
    },
    name() {
      return state.welcomeGameState.name
    },
    username() {
      return state.username
    }
  },

  methods: {
    async getGameStatus() {
      this.isLoading = true;
      const cachedUsername = sessionStorage.getItem('username') || this.selectedPlayer

      const { gameId } = this.$route.params
      console.log('Game ID: ', gameId)

      const resp = await fetch(`/api/game-status/${gameId}?username=${cachedUsername}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
      })
      let body = await resp.json()
      this.isLoading = false
      console.log('get game state, ', body)

      socket.disconnect()
      socket.io.opts.query = {
        username: cachedUsername || '',
        gameId: gameId
      }
      socket.connect()
      state.updateWelcomeGameState(body.welcomeGameState)
      if (this.players.includes(cachedUsername))  {
        state.updateUsername(cachedUsername)
      }
      if (body.roundState) {
        state.updateRoundState(body.roundState)
      }
      if (body.trickState) {
        state.updateTrickState(body.trickState)
      }
      if (body.handState) {
        state.updateHandState(body.handState)
        state.updateEligibleCardsInHand()
      }
    },
    async joinGame() {
      this.isLoading = true;

      const { gameId } = this.$route.params

      // if rejoining, call game status. If new player call join game
      let resp = {}
      let username = this.value
      if (this.disconnectedPlayers.length && this.showReconnect && this.selectedPlayer) {
        username = this.selectedPlayer
        resp = await fetch(`/api/game-status/${gameId}?username=${username}`, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        })
      } else {
        if (this.validUsername() !== true) {
          this.snackbarMessage = 'Invalid username'
          this.snackbar = true
          return
        }
        resp = await fetch(`/api/join-game/${gameId}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ username: username})
        })
      }

      let body = await resp.json()
      console.log('join game body, ', body)

      state.updateWelcomeGameState(body.welcomeGameState)
      // re-establish socket with username in query param
      socket.disconnect()
      socket.io.opts.query = {
        username: username || '',
        gameId,
      }
      socket.connect()

      socket.emit("join-game", username, () => {
        this.isLoading = false;
        this.value = ''
      });

      state.updateUsername(username)

      this.isLoading = false
    },
    async startGame() {
      if (this.players.length < 2) {
        this.snackbarMessage = 'Not enough players'
        this.snackbar = true
        return
      }
      this.isLoading = true;
      const { gameId } = this.$route.params

      await fetch(`/api/start-game/${gameId}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
      })

      this.isLoading = false
    },
    validUsername() {
      if (this.value.length < 3) {
        return 'Username must be at least 3 characters'
      }
      if (this.value.length > 10) {
        return 'Username must be 10 or fewer characters'
      }
      if (/\s/g.test(this.value)) {
        return 'Username cannot contain space'
      }
      if (this.players.includes(this.value)) {
        return 'Username already taken'
      }
      if (this.players.length >= 10) {
        return 'Game is full'
      }
      return true
    },
    toggleReconnect() {
      this.showReconnect = !this.showReconnect
    },
    chipClicked(player) {
      this.selectedPlayer = player
    },
    isSelected(player) {
      return this.selectedPlayer === player
    }
  },

  async created() {
    await this.getGameStatus()
    if (this.status === '' || this.status === 'NOT_STARTED') {
      // hack to force the user to reload the game data from the welcome screen
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.outlined-chip {
  border: 2px solid rgb(59, 150, 247); /* Adjust the border style as needed */
}
</style>