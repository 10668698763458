<template>
<!-- Welcome, when button is clicked, start polling the game status -->
  <v-container class="d-flex align-center justify-center">
    <div v-if="!gamesFetched">
      <p>Welcome to skull king! Let's get started</p>
      <br>
      <v-row class="d-flex justify-center">
        <v-btn color="primary" @click="getGames">
          Continue
        </v-btn>
      </v-row>

    </div>
    <div v-else>

      <p v-if="games.length" class="text-body-1 justify-center d-flex">Choose a game to join</p>
      <v-card v-for="(game, index) in games" :key="index" style="margin-bottom:4px;">
        <v-card-title>
          {{ game.name }}
        </v-card-title>
        <v-card-subtitle>
            Round: {{ game.roundNumber }} / 10
        </v-card-subtitle>
        <v-card-text>
          <v-chip 
            v-for="player in game.players" 
            v-bind:key="player"
          >{{ player }}</v-chip>
          <div class="d-flex justify-center" style="margin-top:8px;">
            <v-btn color="primary" @click="navToGame(game.id)">
              Join Game
            </v-btn>
          </div>
          </v-card-text>
      </v-card>

      <p v-if="errorMessage !== ''">{{ errorMessage }}</p>
      <v-dialog v-model="passkeyModalOpen" max-width="500">
        <v-card>
          <v-card-title class="headline"><v-icon size="24" style="margin-right:4px;">$PasskeyIcon</v-icon>Create a passkey</v-card-title>
          <v-card-text>
            <create-passkey :showReason="showModalReason" />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeModal()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="mx-auto" width="370">
        <v-card-title>
          Create a new game
        </v-card-title>
          <v-card-text>
            <form @submit.prevent="createGame">
              <v-row class="d-flex justify-center mb-2" style="margin-top:8px">
                <v-btn color="primary" @click="usePasskey">
                  <v-icon size="24" style="margin-right:4px;">$PasskeyIcon</v-icon> Use passkey
                </v-btn>
              </v-row>
              <v-row class="d-flex justify-center">
                 <p class="underline-text"><a @click="showPasskeyModal">Create a passkey</a></p>
              </v-row>
              <v-row v-if="passkeyErrMsg" class="d-flex mt-2 justify-center">
                 <p class="text-body-2" style="color:red;">{{ passkeyErrMsg }}</p>
              </v-row>

              <div class="or-line">
                <div class="line"></div>
                <div class="or-word">or</div>
                <div class="line"></div>
              </div>

              <div class="text--primary">
                  <v-text-field type="password" v-model="password" label="Password" autocomplete="off"></v-text-field>
              </div>
              <v-row class="d-flex justify-center">
                <v-btn color="primary" @click="createGame">
                  Use password
                </v-btn>
              </v-row>
            </form>
          </v-card-text>
      </v-card>
    </div>

  </v-container>

  <v-snackbar
      v-model="snackbar"
      :timeout="1500"
    >
    {{ snackbarMessage }}

    <template v-slot:actions>
      <v-btn
        color="blue"
        variant="text"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { socket, state } from "@/socket";
import CreatePasskey from './CreatePasskey.vue';
import axios from 'axios'

function encode(arraybuffer) {
  let s = String.fromCharCode.apply(null, new Uint8Array(arraybuffer))
  return window.btoa(s).replace(/\+/g,'-').replace(/\//g, '_')
}

function decode(str) {
  let s = window.atob(str.replace(/-/g, '+').replace(/_/g, '/'))
  let bytes = Uint8Array.from(s, c=>c.charCodeAt(0))
  return bytes.buffer
}




export default {
  components: { CreatePasskey },
  name: "Welcome",

  data() {
    return {
      isLoading: false,
      password: '',
      value: sessionStorage.getItem('username') || '',
      errorMessage: '',
      joined: false,
      snackbar: false,
      snackbarMessage: '',
      showReconnect: false,
      selectedPlayer: '',
      games: [],
      gamesFetched: false,
      passkeyModalOpen: false,
      passkeyErrMsg: ''
    }
  },

  methods: {
    async onSubmit() {
      this.isLoading = true;

      const resp = await fetch('/api/create-game', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ password: this.value})
      })
      this.gameStatus = await resp.json()
      console.log('get status, ', this.gameStatus)

      if (this.gameStatus === 'NOT_STARTED') {
        socket.connect()

        socket.timeout(5000).emit("chat message", this.value, () => {
          this.isLoading = false;
          this.value = ''
        });
      } else {
        this.isLoading = false
        this.value = ''
      }
    },
    async getGames() {
      this.isLoading = true 

      const resp = await fetch(`/api/games?`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
      })

      const body = await resp.json()
      this.isLoading = false 
      console.log('get games body: ', body)

      this.games = body
      this.gamesFetched = true
    },
    async createGame() {
      this.isLoading = true;

      const resp = await fetch('/api/create-game', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ password: this.password})
      })
      let body = await resp.json()
      console.log('create game body, ', body)
      this.password = ''
      this.loading = false

      if (body.welcomeGameState.status !== 'CREATED') {
        let {status} = body.welcomeGameState
        this.snackbar = true 
        this.snackbarMessage = status === 'TOO_MANY_GAMES' ? 'Sorry, too many games in progress' : 'Wrong password, try again'
        return
      }
      this.errorMessage = ''
      state.updateWelcomeGameState(body.welcomeGameState)
      this.getGames()
    },
    navToGame(id) {
      console.log('naving to game')
      this.$router.push({ path: `/game/${id}` })
    },
    async usePasskey() {
      // if the device has done passkeys before, continue to passkey login 
      return this.createGameWithPasskey()
    },
    async createGameWithPasskey() {
      let options = {}
      try {
        const { data } = await axios.post('/api/generate-challenge', {purpose: 'authentication'})
        options = data
      } catch (error) {
        console.log(error)
        this.passkeyErrMsg = 'Sorry, something went wrong, try again'
        return
      }


      options.publicKey.challenge = decode(options.publicKey.challenge)

      let key = null
      try {
        key = await navigator.credentials.get(options)
      } catch (error) {
        this.showPasskeyModal()
        this.showModalReason = true
        console.log(error)
        return
      }

      let validateReq = {
        id: key.id,
        rawId: encode(key.rawId),
        response: {
          authenticatorData: encode(key.response.authenticatorData),
          clientDataJSON: encode(key.response.clientDataJSON),
          signature: encode(key.response.signature),
          userHandle: encode(key.response.userHandle),
        },
        type: key.type
      }

      // create game
      try {
        const { data: body } = await axios.post('/api/create-game', {
          passkey: validateReq
        })


        if (body.welcomeGameState.status !== 'CREATED') {
          let {status} = body.welcomeGameState
          this.snackbar = true 
          this.snackbarMessage = status === 'TOO_MANY_GAMES' ? 'Sorry, too many games in progress' : 'Wrong password, try again'
          return
        }
        this.errorMessage = ''
        state.updateWelcomeGameState(body.welcomeGameState)
        this.getGames()

        this.errorMsg = ''
        this.passkeyErrMsg = ''
      } catch (error) {
        console.log(error)
        if (error.response) {
          this.passkeyErrMsg = error.response.data
        } else {
          this.passkeyErrMsg = 'Sorry, couldn\'t log in with a passkey'
        }
        return
      }
    },
    showPasskeyModal() {
      this.showModalReason = false
      this.passkeyModalOpen = true
    },
    closeModal() {
      this.passkeyModalOpen = false
    }
  },

  created() {
    socket.disconnect()
  }

}
</script>

<style scoped>
.outlined-chip {
  border: 2px solid rgb(59, 150, 247); /* Adjust the border style as needed */
}

.or-line {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: rgb(166, 166, 166);
}

.or-word {
  padding: 0 16px;
}

.underline-text {
  text-decoration: underline;
  cursor: pointer;
}
</style>