<template>
  <div>
    Played cards order
    <v-radio-group v-model="playedCardOrder">
      <v-radio label="Most recent card on right" value="right"></v-radio>
      <v-radio label="Most recent card on left" value="left"></v-radio>
    </v-radio-group>
  </div>
  <div>
    Card Size
    <v-radio-group v-model="cardSize">
      <v-radio label="Normal" value="normal"></v-radio>
      <v-radio label="Small" value="small"></v-radio>
    </v-radio-group>
  </div>
</template>

<script>

import { state } from "@/socket";

export default {
  name: 'PlayerSettings',
  data() {
    return {
      playedCardOrder: state.playerSettings.playedCardOrder,
      cardSize: state.playerSettings.cardSize
    }
  },
  watch: {
    playedCardOrder(newOrder) {
      this.updatePlayedCardOrder(newOrder)
    },
    cardSize(newCardSize) {
      this.updateCardSize(newCardSize)
    }
  },
  methods: {
    updatePlayedCardOrder(newOrder) {
      state.updatePlayedCardOrder(newOrder)
    },
    updateCardSize(newCardSize) {
      state.updateCardSize(newCardSize)
    }
  }
}
</script>

<style scoped>
.wrap-text {
   white-space: normal;
   -webkit-line-clamp: unset !important;
}
</style>