import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import PasskeyIcon from './icons/PasskeyIcon.vue'
import Welcome from './components/Welcome.vue'
import Game from './components/Game.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases } from 'vuetify/iconsets/mdi-svg'

const aliasesCustom = {
  ...aliases,
  PasskeyIcon,
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliasesCustom
    },
  }
})

const routes = [
  { path: '/', component: Welcome },
  { path: '/game/:gameId', component: Game }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

createApp(App).use(vuetify).use(router).mount('#app')