<template>
  <div>
    <p>Thank you for playing the game and sharing feedback!</p>
    <br>
    <div v-for="(update, index) in updatesToShow" :key="index">
      <p class="text-body-1">{{ update.date }}</p>
      <p v-for="(change, changeIndex) in update.changes" :key="changeIndex" class="text-body-2">
        - {{ change }}
      </p>
      <br>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GameUpdates',
  computed: {
    updatesToShow() {
      const cv = parseInt(this.currentVersion)
      console.log('Current version: ', cv)
      if (cv >= this.updates.length) {
        return this.updates
      }
      return this.updates.slice(cv)
    }
  },
  data() {
    return {
      currentVersion: localStorage.getItem('currentVersion') || 0,
      updates: [{
        date: 'Dec 17, 2023',
        changes: [
          'Users who close their tab / browser can rejoin ongoing games',
          'Add settings to choose the size and order that cards appear',
          'Increase the number of times the deck is shuffled between rounds from 5 to a random number between 1000-2000',
          'Pseudo Tigress: At the start of a round, prior to shuffling and dealing cards, an extra Pirate OR Escape card is inserted in the deck to imitate the Tigress. The extra card is removed at the end of the round.',
        ]
      },{
        date: 'Dec 25, 2023',
        changes: [
          'Added game stats and game updates modals',
          'Minor bug fixes'
        ]
      },{
        date: 'Dec 27, 2023',
        changes: [
          'Added support for up to 5 games running at the same time',
          'Added a spectator view'
        ]
      },{
        date: 'Jan 13, 2024',
        changes: [
          'Added support for passkeys!! Use a passkey to create new games instead of a password.'
        ]
      }]
    }
  },
  created() {
    this.currentVersion = localStorage.getItem('currentVersion') || 0
    localStorage.setItem('currentVersion', this.updates.length)
  }
}
</script>

<style scoped>
.wrap-text {
   white-space: normal;
   -webkit-line-clamp: unset !important;
}
</style>