<template>
      <v-list v-model:opened="open" >

      <v-list-group value="CardHierarchy">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Card Hierarchy"
            ></v-list-item>
          </template>

        <v-list-item
          v-for="(item, index) in cardHierarchy"
          :key="index"
        >
          <v-list-item-title class="wrap-text">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="wrap-text">{{ item.subtitle }}</v-list-item-subtitle>

        </v-list-item>
      </v-list-group>
      <v-list-group value="Scoring">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Scoring"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="(item, index) in scoring"
          :key="index"
        >
          <v-list-item-title class="wrap-text">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="wrap-text">{{ item.subtitle }}</v-list-item-subtitle>

        </v-list-item>
      </v-list-group>
      <v-list-group value="EdgeCases">
          <template v-slot:activator="{ props }">
            <v-list-item
              v-bind="props"
              title="What happens when..."
            ></v-list-item>
          </template>

        <v-list-item
          v-for="(item, index) in edgeCases"
          :key="index"
        >
          <v-list-item-title class="wrap-text">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="wrap-text">{{ item.subtitle }}</v-list-item-subtitle>

        </v-list-item>
      </v-list-group>
      <v-list-group value="Bonus Points">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            title="Bonus Points"
          ></v-list-item>
        </template>

        <v-list-item
          v-for="(item, index) in bonusPoints"
          :key="index"
        >
          <v-list-item-title class="wrap-text">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="wrap-text">{{ item.subtitle }}</v-list-item-subtitle>

        </v-list-item>
      </v-list-group>
    </v-list>
</template>

<script>
export default {
  name: 'GameRules',
  data() {
    return {
      open: [],
      cardHierarchy: [{
        title: 'Skull King',
        subtitle: 'Beats all except Mermaids.'
      },{
        title: 'Pirate',
        subtitle: 'Beats all except the Skull King.'
      },{
        title: 'Mermaid',
        subtitle: 'Beats all numbered cards AND the Skull King.'
      },{
        title: 'Trump Suit (Black)',
        subtitle: 'Highest trump suit beats all standard suit cards.'
      },{
        title: 'Standard Suit (Green, Yellow, Purple)',
        subtitle: 'Highest card of the lead suit wins.'
      },{
        title: 'Escape',
        subtitle: 'Loses to every card.'
      },{
        title: 'Tigress',
        subtitle: 'Either an Escape or Pirate. Decide when playing the card.'
      },{
        title: 'White Whale',
        subtitle: 'Highest numbered card wins regardless of character cards, lead suit, or trump suit.'
      },{
        title: 'Kraken',
        subtitle: 'All cards lose.'
      }],
      edgeCases: [{
        title: 'Skull King, Pirate, and Mermaid are all played',
        subtitle: 'When the Skull King, Pirate, and Mermaid are all played in the same trick, the first Mermaid wins.'
      },{
        title: 'White Whale and no number cards',
        subtitle: 'If the White Whale is played and no number cards are played, no one wins the trick.'
      },{
        title: 'Multiple Pirates are played',
        subtitle: 'The first Pirate beats other Pirates.'
      },
      {
        title: 'Multiple Mermaids are played',
        subtitle: 'The first Mermaid beats other Mermaids.'
      },{
        title: 'White Whale and the Kraken',
        subtitle: 'When both the White Whale and Kraken are played in the same trick, the SECOND card played prevails.'
      }],
      bonusPoints: [{
        title: 'When are bonus points earned?',
        subtitle: 'When you win a trick AND hit your bet for the round.'
      },{
        title: 'Standard 14\'s',
        subtitle: '10 points for each card.'
      },{
        title: 'Trump 14',
        subtitle: '20 points.'
      },{
        title: 'Mermaid',
        subtitle: '20 points for each Mermaid caught by a pirate.'
      },{
        title: 'Pirate',
        subtitle: '30 points for each Pirate caught by a Skull King.'
      },{
        title: 'Skull King',
        subtitle: '40 points when caught by a Mermaid.'
      }],
      scoring: [{
        title: 'How scoring works',
        subtitle: 'Scores for a round are determined by your bet and the number of tricks you won'
      },{
        title: 'Bet 0 won 0',
        subtitle: '(+10) x the round number. e.g. Round 7: bet 0, won 0 = 70'
      },{
        title: 'Bet 0 and won 1 or more tricks',
        subtitle: '(-10) x the round number. e.g. Round 7: bet 0, won 1 = -70.'
      },{
        title: 'Bet 1 or more and won the exact amount of the bet',
        subtitle: '(+20) x the size of bet. e.g. Bet 1, won 1 = 20'
      },{
        title: 'Bet 1 or more and did not meet the bet',
        subtitle: '(-10) x the distance to the bet. e.g. Bet 1, won 0 = -10. Bet 1, won 3 = -20.'
      }]
    }
  }
}
</script>

<style scoped>
.wrap-text {
   white-space: normal;
   -webkit-line-clamp: unset !important;
}
</style>