import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
  connected: false,
  fooEvents: [],
  barEvents: [],
  admitUserEvents: [],
  hand: {
    cards: []
  },
  gameMessage: '',
  gameMessages: [],
  username: '',
  playerSettings: {
    playedCardOrder: 'right',
    cardSize: 'normal'
  },
  welcomeGameState: {
    status: '',
    players: [],
    round: 0,
    disconnectedPlayers: [],
    name: ''
  },
  roundState: {
    roundNumber: 0,
    tricksToPlay: 0,
    trickNumber: 0,
    scores: {},
    state: '',
  },
  trickState: {
    leadSuit: '',
    startingPlayer: '',
    winningPlayer: '',
    winningCard: {}
  },
  askedForCard: false,
  selectedCardIndex: -1,
  updateAskedForCard(b) {
    this.askedForCard = b
  },
  updateWelcomeGameState(wgs) {
    this.welcomeGameState = wgs
  },
  updateRoundState(rs) {
    console.log('Setting round state: ', rs)
    this.roundState = rs
  },
  updateTrickState(ts) {
    this.trickState = ts
    this.updateEligibleCardsInHand()
  },
  updateHandState(hand) {
    this.hand = hand 
    this.hand.cards = this.hand.cards.sort((a, b) => {
      
      // sort number cards of the same suit
      if (a.suit === b.suit) {
        return a.number - b.number 
      }
      // sort suits
      if (a.suit && b.suit) {
        if (a.suit === b.suit) {
          return 0
        }
        // black should be greater than other suits
        if (a.suit === 'BLACK') {
          return 1
        }
        if (b.suit === 'BLACK') {
          return -1
        }
        return a.suit < b.suit ? -1 : 1
      }
      // sort suits relative to characters and specials
      if (a.suit) {
        // a has suit and b does not
        return -1 
      }
      if (b.suit) {
        // b has suit and a does not
        return 1
      }
      // sort characters
      if (a.character && b.character) {
        if (a.character === b.character) {
          return 0
        }
        return a.character < b.character ? -1 : 1
      }
      // sort characters relative to spcecials 
      if (a.character && b.special) {
        return -1
      }
      if (a.special && b.character) {
        return 1
      }
      // sort specials 
      if (a.special === b.special) {
        return 0
      }
      return a.special < b.special ? -1 : 1
    })
  },
  updateUsername(un) {
    this.username = un
    sessionStorage.setItem('username', un)
  },
  removeCardFromHand(index) {
    this.hand.cards.splice(index, 1)
    this.selectedCardIndex = -1
  },
  selectCard(index) {
    if (!this.hand.cards[index].isEligible) {
      // cannot select ineligible cards
      return 
    }
    this.selectedCardIndex = index 
  },
  updateEligibleCardsInHand() {
    // update the hand cards with whether they are playable based on the lead suit
    // If the lead suit has been set, 
    let leadSuit = ['BLACK', 'GREEN', 'YELLOW', 'PURPLE'].find(e => e === this.trickState.leadSuit)
    let handHasLeadSuit = leadSuit ? this.hand.cards.find(c => c.suit === leadSuit) : false
    this.hand.cards.forEach((c, i) => {
      if (handHasLeadSuit && !c.character && !c.special) {
        this.hand.cards[i].isEligible = c.suit === leadSuit
      } else {
        this.hand.cards[i].isEligible = true
      }
    })
  },
  updatePlayedCardOrder(newOrder) {
    this.playerSettings.playedCardOrder = newOrder
  },
  updateCardSize(newCardSize) {
    this.playerSettings.cardSize = newCardSize
  }
});

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.NODE_ENV === "production" ? undefined : "http://localhost:3020";

export const socket = io(URL, {
  autoConnect: false,
  query: {
    username: sessionStorage.getItem('username') || ''
  }
});

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on('reconnect_attempt', () => {
  socket.io.opts.query = {
    username: sessionStorage.getItem('username') || ''
  }
})

socket.on("foo", (...args) => {
  state.fooEvents.push(args);
});

socket.on("bar", (...args) => {
  state.barEvents.push(args);
});

socket.on('admit-user', (...args) => {
  state.admitUserEvents.push(args)
})

socket.on('update-welcome-game-state', gameState => {
  state.updateWelcomeGameState(gameState)
})

socket.on('update-round-state', rs => {
  state.updateRoundState(rs)
})

socket.on('update-trick-state', ts => {
  state.updateTrickState(ts)
})

socket.on('deal-cards', hand => {
  state.updateHandState(hand)
  state.askedForCard = false
  state.updateEligibleCardsInHand()
})

socket.on('in-game-message', msg => {
  state.gameMessage = msg
  state.gameMessages.unshift({id: state.gameMessages.length, msg})
})

socket.on('ask-for-card', () => {
  state.updateAskedForCard(true)
})

socket.on('end-game', () => {
  location.reload()
})
